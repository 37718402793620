<!-- 通用设置 -->
<template>
    <div class="curren">
        <div class="curren_qie">
            <el-tabs v-model="activeName">
                <!-- 会员-------------------------------------------------------------------------- -->
                <el-tab-pane class="currenel-pang" label="会员设置" name="member">
                    <div class="curren_qie_form">
                        <el-form ref="form" :model="memberForm">
                            <el-form-item label="会员名称前缀：">
                                <el-input v-model="memberForm['name_prefix']"></el-input>
                            </el-form-item>

                            <!-- <el-form-item label="会员积分比例:">
                                <el-input type="number" v-model="memberForm['point_ratio']">
                                    <span slot="append">%</span>
                                </el-input>
                            </el-form-item> -->

                            <el-form-item label="默认登录方式：">
                                <el-radio v-model="memberForm.login_type" :label="0">密码账号登陆</el-radio>
                                <el-radio v-model="memberForm.login_type" :label="1">验证码登录</el-radio>
                            </el-form-item>

                            <el-form-item label="是否开启会员注册：">
                                <el-radio v-model="memberForm.register_open" :label="0">开启</el-radio>
                                <el-radio v-model="memberForm.register_open" :label="1">关闭</el-radio>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
                <!-- 订单------------------------------------------------------------------------ -->
                <el-tab-pane label="订单设置" name="order">
                    <el-form :model="orderForm">
                        <el-form-item>
                            <div class="curren_qie_continer">
                                <div class="curren_qie_span">
                                    <span class="desc">自动取消订单：</span>
                                    <span class="erspan">提交订单</span>
                                    <el-input-number :min="10" :max="1440" :controls="false" class="input-t"
                                        v-model="orderForm.auto_cancel_time" placeholder="请输入内容"></el-input-number>
                                    <span class="info">分钟内未付款，自动取消订单</span>
                                </div>
                                <div class="curren_radio_p">
                                    <p>订单取消时间必须在10-1440分钟之间</p>
                                    <el-checkbox v-model="orderForm.auto_cancel_open" :true-label="0"
                                        :false-label="1">关闭自动取消订单</el-checkbox>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="curren_qie_continer">
                                <div class="curren_qie_span">
                                    <span class="desc">自动确认收货：</span>
                                    <span class="erspan">发货后</span>
                                    <el-input-number :min="1" :max="30" :controls="false" class="input-t"
                                        v-model="orderForm.auto_take_delivery_time" placeholder="请输入内容"></el-input-number>
                                    <span class="info">天，自动确认收货</span>
                                </div>
                                <div class="curren_radio_p">
                                    <p>自动确认收货时间必须在1-30天之间</p>
                                    <el-checkbox v-model="orderForm.auto_take_delivery_open" :true-label="0"
                                        :false-label="1">关闭自动确认收货</el-checkbox>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="curren_qie_continer">
                                <div class="curren_qie_span">
                                    <span class="desc">确认收货后售后：</span>
                                    <span class="erspan">确认收货</span>
                                    <el-input-number :min="1" :max="30" :controls="false" class="input-t"
                                        v-model="orderForm.after_sales_time" placeholder="请输入内容"></el-input-number>
                                    <span class="info">天内，可申请售后</span>
                                </div>
                                <div class="curren_radio_p">
                                    <p>自动确认收货时间必须在1-30天之间</p>
                                    <el-checkbox v-model="orderForm.after_sales_open" :true-label="0"
                                        :false-label="1">确认收货后不支持售后</el-checkbox>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="curren_qie_type">
                                <div class="curren_yh">
                                    <div class="price">
                                        <span class="desc">申请退款金额：</span>
                                        <el-radio v-model="orderForm.refund_price_change_open" :label="1">允许用户修改</el-radio>
                                        <el-radio v-model="orderForm.refund_price_change_open" :label="0">不允许用户修改</el-radio>
                                    </div>
                                    <p>修改退款金额时，不会超过该商品的实付款</p>
                                </div>
                                <div class="curren_fp">
                                    <div class="invoice">
                                        <span class="desc">是否开具发票：</span>
                                        <el-radio v-model="orderForm.invoice_open" :label="1">显示</el-radio>
                                        <el-radio v-model="orderForm.invoice_open" :label="0">隐藏</el-radio>
                                    </div>
                                </div>

                                <div class="curren_fp_type">
                                    <div class="fp_type">
                                        <span class="desc">发票类型：</span>
                                        <el-checkbox v-model="orderForm.invoice_type" v-for="item in invoiceTypeComp || []"
                                            :label="item" :key="item.type">{{ item.name }}</el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <!-- 商品---------------------------------------------------------------------------- -->
                <el-tab-pane label="商品设置" name="good">
                    <div class="curren_priop">
                        <el-form :model="goodForm" ref="goodForm">
                            <el-form-item>
                                <div class="curren_fp">
                                    <div class="change_type">
                                        <span class="desc">加载图标：</span>
                                        <el-radio-group v-model="goodForm.icon_type">
                                            <el-radio :label="0">系统默认</el-radio>
                                            <el-radio :label="1">自定义</el-radio>
                                        </el-radio-group>
                                    </div>

                                    <div class="upload">
                                        <div class="skeletonize">
                                            <div v-if="!!goodForm.icon_url">
                                                <img :src="good_icon_url" v-if="goodForm.icon_type == 0" />
                                                <img :src="goodForm.icon_url || '#'" v-else @click="handleImageClick" />
                                            </div>
                                            <i class="el-icon-plus" v-else></i>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                            <div class="curren_bo">
                                <div class="curren_fp_lx">
                                    <!-- 商品默认库存设置 -->
                                    <div class="inventory">
                                        <span class="desc">商品默认库存设置：</span>
                                        <!-- <el-input-number :controls="false" v-model="goodForm.max_stock" :min="100"
                                                :max="99999"></el-input-number> -->
                                        <el-input type="number" v-model="goodForm.max_stock" :min="100"
                                            :max="99999"></el-input>
                                    </div>
                                    <div class="item-num">
                                        <span class="desc">商品货号规则：</span>
                                        <el-checkbox v-for=" (item, index) in goodForm.item_no_rules.header" :key="index"
                                            @change="handleRulesChange($event, item, index)"
                                            v-model="goodForm.item_no_rules.header[index]['selected']">{{ item.name
                                            }}</el-checkbox>
                                    </div>
                                    <div class="curren_tab">
                                        <el-table :data="goodForm.item_no_rules.rules" style="width: 100%">
                                            <el-table-column prop="date" label="排序" width="180">
                                                <template>
                                                    <i class="touch"></i>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="name" label="内容" width="180">
                                                <template slot-scope="scope">
                                                    <el-form-item>
                                                        <span class="span" v-if="scope.row.type != 'diy'">{{ scope.row.name
                                                        }}</span>
                                                        <el-input class="bdnoe" v-model="scope.row.name" placeholder="请输入内容"
                                                            v-else></el-input>
                                                    </el-form-item>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="content" label="内容">
                                                <template slot-scope="scope">
                                                    <el-form-item :prop="`item_no_rules.rules[${scope.$index}]['content']`"
                                                        :rules="{ required: true, validator: (rules, value, callback) => valid(rules, scope.row, callback), trigger: 'blur' }">
                                                        <el-input :disabled="scope.row.type == 'timestamp'"
                                                            v-model="scope.row.content" placeholder="请输入内容">
                                                            <span slot="append" v-if="scope.row.name == '随机数'">位</span>
                                                        </el-input>
                                                        <i class="el-icon-delete" @click="deleporit(scope)"></i>
                                                    </el-form-item>

                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                    <el-button icon="el-icon-circle-plus-outline" @click="handleIncrement"
                                        v-show="(goodForm.item_no_rules.header && goodForm.item_no_rules.header.find(item => item.type == 'diy') ? goodForm.item_no_rules.header.find(item => item.type == 'diy').selected && goodForm.item_no_rules.rules.filter(item => item.type == 'diy').length == 0 : false)">新增</el-button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </el-tab-pane>
                <!-- 显示---------------------------------------------------------------------------- -->
                <el-tab-pane label="显示设置" name="display">
                    <el-form :model="displayForm">
                        <div class="curren_xar">
                            <!-- :class="{ 'active': index == 5 }" -->
                            <div v-for="(item, index) in radioList" :key="index" class="curren_fp">
                                <span class="desc">{{ item.label }}</span>
                                <el-radio-group v-model="displayForm[item.value]">
                                    <el-radio :label="1">显示</el-radio>
                                    <el-radio :label="0">隐藏</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="curren_fp">
                                <span class="desc">热搜关键词：</span>
                                <el-select v-model="displayForm.hot_search" allow-create multiple filterable
                                    default-first-option placeholder="请选择热搜关键词">
                                    <el-option v-for="(item, index) in displayForm.hot_search" :key="index" :label="item"
                                        :value="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <diy-footer-button v-if="hasPerm(['setting.common.update'])">
                <el-button type="primary" @click="handleSave">保存</el-button>
            </diy-footer-button>
        </div>
        <!-- 图片 -->
        <pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>
    </div>
</template>
  
<script>
import { Message } from 'element-ui';
import Sortable from 'sortablejs';
import { mapState } from 'vuex';
const invoiceType = [
    {
        "type": 1,
        "name": "增值税普通发票(纸质)",
        "selected": false
    },
    {
        "type": 2,
        "name": "增值税专用发票(电子)",
        "selected": false
    },
    {
        "type": 3,
        "name": "增值税专用发票(纸质)",
        "selected": false
    }
]


export default {
    data() {
        return {
            activeName: "member",//tab激活名称
            memberForm: {
                login_type: "",
                name_prefix: "",
                point_ratio: "",
                register_open: ""
            },//会员form
            orderForm: {
                auto_cancel_time: 0,//自动取消订单
                auto_cancel_open: 1,//自动取消订单 1:开启 0:关闭
                auto_take_delivery_time: 0,// 自动收货
                auto_take_delivery_open: 1,//自动收货 1:开启 0:关闭
                after_sales_time: 0,//确认收货售后
                after_sales_open: 1,//确认收货后售后时间 1:开启 0:关闭
                refund_price_change_open: 0,//退款金额更改状态 1:开启 0:关闭
                invoice_open: 0,//开票状态 1:开启 0:关闭
                invoice_type: [],//发票类型
            },//订单form
            goodForm: {
                icon_type: 0,//通用配置图标类型 1:自定义 0:系统默认
                icon_url: "",//平台 后台icon地址
                max_stock: 100,
                item_no_rules: {
                    header: [],
                    rules: []
                },//通用配置 商品货号规则

            },//商品form
            is_multiple: 0,//图片单选还是多选 0：单选 1：多选
            displayForm: {
                comment_open: 0,//通用配置显示设置评价状态 1:显示 0:不显示
                give_point_open: 0,//通用配置显示设置赠送积分状态 1:显示 0:不显示
                original_price_open: 0,//通用配置显示设置原价状态 1:显示 0:不显示
                sales_open: 0,//通用配置显示设置销量状态 1:显示 0:不显示
                sku_open: 0,//通用配置显示设置货号状态 1:显示 0:不显示
                stock_open: 0,//通用配置显示设置库存状态 1:显示 0:不显示
                unit_open: 0,//通用配置显示设置单位状态 1:显示 0:不显示
                weight_open: 0,//通用配置显示设置重量状态 1:显示 0:不显示
                hot_search: [],
            },//显示form
            radioList: [
                {
                    label: "是否显示销量：",
                    value: "sales_open"
                },
                {
                    label: "是否显示库存：",
                    value: "stock_open"
                },
                {
                    label: "是否显示货号：",
                    value: "sku_open"
                },
                {
                    label: "是否显示评价：",
                    value: "comment_open"
                },
                {
                    label: "是否显示原价：",
                    value: "original_price_open"
                },
                {
                    label: "是否显示赠送积分：",
                    value: "give_point_open"
                },
                {
                    label: "是否显示单位：",
                    value: "unit_open"
                },
                {
                    label: "是否显示重量：",
                    value: "weight_open"
                },
            ],//商品货号规则数据
        }
    },
    created() {
        this.getMemberInfo();
        this.getOrderInfo();
        this.getGoodInfo();
        this.getShowInfo();

    },
    mounted() {
        this.handleMove();
    },
    methods: {
        valid(rules, { name, content, type }, callback) {
            console.log(name, content, type);
            if (type == 'timestamp') {
                return callback();
            }
            if (!content && type != 'timestamp') {
                return callback(callback(new Error(`${name}内容不能为空`)))
            }
            if (type != 'random' && content.length > 5) {
                return callback(new Error(`${name}内容长度不超过${type == 'item_no_prefix' ? 5 : type == 'diy' ? 5 : 5}个字!`))
            }
            if (type == 'random' && Number(content) > 5) {
                return callback(new Error(`${name}位数最大值不可超过5!`))
            }
            callback();
        },
        // 获取会员配置信息
        getMemberInfo() {
            this.$get(this.$apis.commonMemberApi).then(res => {
                if (res.code != 200) {
                    return Message.error(res.message);
                }
                this.$nextTick(() => {
                    this.$set(this, "memberForm", {
                        login_type: res.data['common.member.login_type'],
                        name_prefix: res.data['common.member.name_prefix'],
                        point_ratio: res.data['common.member.point_ratio'],
                        register_open: res.data['common.member.register_open'],
                    })
                })
            })
        },
        // 设置会员配置信息
        setMemberInfo(params) {
            // 编辑会员配置  // "common.member.point_ratio": params.point_ratio,
            this.$put(this.$apis.commonMemberApi, {
                "common.member.login_type": params.login_type,
                "common.member.name_prefix": params.name_prefix,

                "common.member.register_open": params.register_open
            }).then(res => {
                if (res.code == 200) {
                    Message.success("编辑会员配置成功!");
                } else {
                    Message.error(res.message);
                }
            })
        },
        // 获取订单配置信息
        getOrderInfo() {
            this.$get(this.$apis.commonOrderApi).then(res => {
                if (res.code != 200) {
                    return Message.error(res.message);
                }
                const typeList = res.data['common.order.invoice_type'].filter(item => item.selected == true).map(item => item.type);
                // 通过typeList找到invoiceType中的属性并改变select状态
                for (const id of typeList) {
                    invoiceType.find(item => item.type == id).selected = true
                }
                this.$nextTick(() => {
                    this.$set(this, "orderForm", {
                        auto_cancel_time: res.data['common.order.auto_cancel_time'],
                        auto_cancel_open: res.data['common.order.auto_cancel_open'],
                        auto_take_delivery_time: res.data['common.order.auto_take_delivery_time'],
                        auto_take_delivery_open: res.data['common.order.auto_take_delivery_open'],
                        after_sales_time: res.data['common.order.after_sales_time'],
                        after_sales_open: res.data['common.order.after_sales_open'],
                        refund_price_change_open: res.data['common.order.refund_price_change_open'],
                        invoice_open: res.data['common.order.invoice_open'],
                        invoice_type: invoiceType.filter(item => item.selected == true),
                    })
                })
            })
        },
        // 设置订单配置信息
        setOrderInfo(params) {
            const typeList = params.invoice_type.map(item => item.type);
            invoiceType.filter(item => {
                return typeList.some(ite => ite != item.type)
            }).map(ite => ite.selected = false)
            for (const id of typeList) {
                invoiceType.find(item => item.type == id).selected = true
            }
            // 编辑订单配置
            this.$put(this.$apis.commonOrderApi, {
                "common.order.auto_cancel_time": params.auto_cancel_time,
                "common.order.auto_cancel_open": params.auto_cancel_open,
                "common.order.auto_take_delivery_time": params.auto_take_delivery_time,
                "common.order.auto_take_delivery_open": params.auto_take_delivery_open,
                "common.order.after_sales_time": params.after_sales_time,
                "common.order.after_sales_open": params.after_sales_open,
                "common.order.refund_price_change_open": params.refund_price_change_open,
                "common.order.invoice_open": params.invoice_open,
                "common.order.invoice_type": invoiceType,
            }).then(res => {
                if (res.code == 200) {
                    Message.success("编辑订单配置成功!");
                } else {
                    Message.error(res.message);
                }
            })
        },
        // 获取商品配置信息
        getGoodInfo() {
            this.$get(this.$apis.commonGoodApi).then(res => {
                if (res.code != 200) {
                    return Message.error(res.message);
                }
                this.$nextTick(() => {
                    this.$set(this, "goodForm", {
                        icon_type: res.data['common.goods.icon_type'],
                        icon_url: res.data['common.goods.icon_url'],
                        max_stock: res.data['common.goods.max_stock'],
                        item_no_rules: res.data['common.goods.item_no_rules'],
                    })
                })
            })
        },
        // 设置商品配置信息
        setGoodInfo(params) {
            // 编辑商品配置
            this.$put(this.$apis.commonGoodApi, {
                "common.goods.icon_type": params.icon_type,
                "common.goods.icon_url": params.icon_type == 0 ? this.good_icon_url : params.icon_url,
                "common.goods.max_stock": params.max_stock,
                "common.goods.item_no_rules": params.item_no_rules,
            }).then(res => {
                if (res.code == 200) {
                    Message.success("编辑商品配置成功!");
                } else {
                    Message.error(res.message);
                }
            })
        },
        // 获取显示配置信息
        getShowInfo() {
            this.$get(this.$apis.commonShowApi).then(res => {
                if (res.code != 200) {
                    return Message.error(res.message);
                }
                this.$nextTick(() => {
                    this.$set(this, "displayForm", {
                        comment_open: res.data['common.show.comment_open'],
                        give_point_open: res.data['common.show.give_point_open'],
                        original_price_open: res.data['common.show.original_price_open'],
                        sales_open: res.data['common.show.sales_open'],
                        sku_open: res.data['common.show.sku_open'],
                        stock_open: res.data['common.show.stock_open'],
                        unit_open: res.data['common.show.unit_open'],
                        weight_open: res.data['common.show.weight_open'],
                        hot_search: res.data['common.show.hot_search']
                    })
                })
            })
        },
        // 设置显示配置信息
        setShowInfo(params) {
            // 编辑显示配置
            this.$put(this.$apis.commonShowApi, {
                "common.show.comment_open": params.comment_open,
                "common.show.give_point_open": params.give_point_open,
                "common.show.original_price_open": params.original_price_open,
                "common.show.sales_open": params.sales_open,
                "common.show.sku_open": params.sku_open,
                "common.show.stock_open": params.stock_open,
                "common.show.unit_open": params.unit_open,
                "common.show.weight_open": params.weight_open,
                "common.show.hot_search": params.hot_search
            }).then(res => {
                if (res.code == 200) {
                    Message.success("编辑显示配置成功!");
                } else {
                    Message.error(res.message);
                }
            })
        },

        // el-table 行拖动
        handleMove() {
            const _this = this;
            const el = document.querySelector('.el-table__body-wrapper tbody');
            Sortable.create(el, {
                // 结束拖拽后的回调函数
                // onEnd({ newIndex, oldIndex }) {
                //     const rules = JSON.parse(JSON.stringify(_this.goodForm.item_no_rules.rules))
                //     console.log('拖动了行，当前序号：' + newIndex, oldIndex, rules);
                //     const currentRow = rules.splice(oldIndex, 1)[0];
                //     rules.splice(newIndex, 0, currentRow);
                //     console.log(rules);
                //     _this.$set(_this.goodForm.item_no_rules, 'rules', rules)
                //     _this.$forceUpdate();
                // }
            })
        },
        // 货架规格选择事件
        handleRulesChange(flag, item, index) {
            // 如果取消了前面三种规格，则需要取消表格中对应的数据  
            if (flag) {  //选中
                if (item.type != 'diy') { //排除自定，自定需要点击添加按钮新增
                    this.goodForm.item_no_rules.rules.splice(index, 0, item);
                }
            } else {
                // 找到对应数据并且移除
                const index = this.goodForm.item_no_rules.rules.findIndex(ite => ite.type == item.type);
                if (index != -1) {
                    this.goodForm.item_no_rules.rules.splice(index, 1);
                }
            }
        },

        // 商品货号数据删除
        deleporit(scope) {

            // 通过id找到数据并移除
            // 时间戳和随机数至少有一个存在不能删除
            if ((scope.row.type == 'timestamp' && this.goodForm.item_no_rules.rules.some(item => item.type == 'random')) || (scope.row.type == 'random' && this.goodForm.item_no_rules.rules.some(item => item.type == 'timestamp')) || (scope.row.type != 'random' && scope.row.type != 'timestamp')) {
                const deleteRow = this.goodForm.item_no_rules.rules.splice(scope.$index, 1);
                // 根据类型去header中设置seleced
                const { type } = deleteRow[0];
                if (type) {
                    this.goodForm.item_no_rules.header.find(ite => ite.type == type).selected = false;
                }
            } else {
                return Message.warning("时间戳和随机数至少存在一个!");
            }
        },

        // 新增
        handleIncrement() {
            const increInfo = {
                "type": "diy",
                "name": "",
                "content": "",
                "id": this.goodForm.item_no_rules.rules[this.goodForm.item_no_rules.rules.length - 1].id + 1
            }
            // 限制添加一个（）
            if (this.goodForm.item_no_rules.rules.filter(item => item.type == 'diy').length == 0) {
                this.goodForm.item_no_rules.rules.push(increInfo)
            } else {
                return Message.warning("只能添加一个自定货号规格!");
            }
        },

        // 选择图片事件
        imgbrand(val) {
            this.$set(this.goodForm, "icon_url", val[0].file_url);
        },
        // 点击图片
        handleImageClick() {
            this.$refs.imglist.photoVisible = true;
        },

        // 保存
        handleSave() {
            const { memberForm, orderForm, goodForm, displayForm } = this;
            if (this.activeName == 'member') {
                this.setMemberInfo(memberForm);
            } else if (this.activeName == 'order') {
                this.setOrderInfo(orderForm);
            } else if (this.activeName == 'good') {
                this.$refs['goodForm']['validate'](valid => {
                    if (valid) {
                        this.setGoodInfo(goodForm);
                    }
                })
            } else if (this.activeName == 'display') {
                this.setShowInfo(displayForm);
            }
        }
    },
    computed: {
        ...mapState(['noLoginGlobal']),
        invoiceTypeComp() {
            return (invoiceType);
        },
        good_icon_url() {
            return this.noLoginGlobal['default.config.list'] ? this.noLoginGlobal['default.config.list']['pc.default.goods_loading'] : '#'
        }
    }
};
</script>
  
<style lang="less" scoped>
.curren {
    width: 100%;

    & .curren_qie {
        width: 100%;

        & .addtion_foot {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            padding-left: 160px;
            box-sizing: border-box;
            height: auto;
            border-top: 1px solid #f1f1f1;
            z-index: 6;
            background-color: #fff;

            .addtion_foot-body {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px !important;
            }
        }

        & /deep/.el-tabs__header {
            background-color: #fff;
            border-radius: 4px;

            & .el-tabs__nav-wrap::after {
                background-color: transparent !important;
            }

            & .el-tabs__item {


                padding: 0;
                width: 96px;
                font-size: 14px;
                font-family: PingFangSC-Medium,
                    PingFang SC;
                font-weight: 500;
                text-align: center;
            }

            & .el-tabs__active-bar {
                // width: 96px !important;
                height: 2px;
                // background: #000D9A;
            }

            & .el-tabs__nav-scroll {
                height: 48px;
                padding-left: 20px;
                border-radius: 4px;

                & .el-tabs__nav {
                    height: 100%;

                    & .el-tabs__active-bar {
                        transform: translateX(96px + 78px);
                    }


                    & .el-tabs__item {
                        // width: 180px;
                        width: auto;
                        height: 100%;
                        font-size: 14px;
                        line-height: 48px;
                        padding-right: 78px;
                        text-align: left;
                        margin: 0;
                    }
                }
            }
        }

        & /deep/.el-tabs__content {
            width: 100%;
            background-color: #FFFFFF;
            border-radius: 4px;
            padding: 20px 0 60px 0;
            box-sizing: border-box;

            & .el-form {
                width: 100%;
                margin-left: 60px;

                & .el-form-item {
                    display: flex;
                    margin-bottom: 24px;

                    & .el-form-item__label {
                        width: 160px;
                        text-align: right;
                    }

                    & .el-input {
                        // width: 356px;
                        // height: 40px;
                        // background: #FFFFFF;
                        // border-radius: 4px;
                        // border: none;
                        // outline: none;

                        & .el-input-group__append {
                            width: 38px;
                            height: 38px;
                            background: #F5F6FA;
                            border-radius: 0px 4px 4px 0px;
                            border: 1px solid #DDDFE6;
                            padding: 0;
                            text-align: center;
                            border: 1px solid #DDDFE6;
                            border-left: none;
                        }
                    }
                }
            }
        }

        & /deep/.el-checkbox__inner {
            width: 16px;
            height: 16px;
            border-radius: 2px;

            &::after {
                left: 5px;
            }
        }

        & /deep/.el-checkbox__label {
            display: inline-block;
            // width: 84px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: #333333;
            line-height: 20px;
            padding-left: 8px;
        }

        & /deep/.el-radio__inner {
            width: 16px;
            height: 16px;
            background: #FFFFFF;
            border: 1px solid #E0E0E0;
        }

        & /deep/.el-radio__label {
            display: inline-block;
            // width: 84px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            padding-left: 8px;
        }

        // 订单
        & .curren_qie_continer {
            width: 100%;
            height: 122px;

            & .curren_qie_span {
                // width: 520px;
                width: 100%;
                height: 40px;

                & .desc {
                    display: inline-block;
                    width: 130px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    text-align: right;
                }

                & .erspan {
                    width: 56px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-right: 8px;
                }

                & .el-input-number {
                    width: 126px !important;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 4px;

                    & /deep/.el-input {
                        width: 100% !important;
                    }
                }

                & .info {
                    width: 182px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-left: 8px;
                }
            }

            & .curren_radio_p {
                width: 100%;
                height: 52px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 130px;
                margin-top: 8px;

                & p {
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 20px;
                }

                & /deep/.el-checkbox__label {
                    width: 112px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #323233;
                    line-height: 20px;
                    padding-left: 8px;
                }
            }
        }

        & .curren_qie_type {
            width: 100%;

            & .curren_yh {
                width: 100%;
                height: 56px;
                margin-bottom: 24px;

                & .price {
                    display: flex;
                    width: 100%;
                    height: 38px;
                    display: flex;
                    align-items: center;

                    & .desc {
                        display: inline-block;
                        width: 130px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        text-align: right;
                    }

                    & /deep/.el-radio__inner {
                        width: 16px;
                        height: 16px;
                        background: #FFFFFF;
                        border: 1px solid #E0E0E0;
                    }

                    & /deep/.el-radio__label {
                        display: inline-block;
                        // width: 84px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        padding-left: 8px;
                    }
                }

                & p {
                    width: 266px;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 20px;
                    margin-left: 130px;

                }


            }

            & .curren_fp {
                width: 100%;
                height: 38px;
                margin-bottom: 15px;

                & .invoice {
                    display: flex;
                    width: 100%;
                    height: 38px;
                    display: flex;
                    align-items: center;

                    & .desc {
                        display: inline-block;
                        width: 130px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        text-align: right;
                    }

                    & /deep/.el-radio__inner {
                        width: 16px;
                        height: 16px;
                        background: #FFFFFF;
                        border: 1px solid #E0E0E0;
                    }

                    & /deep/.el-radio__label {
                        display: inline-block;
                        // width: 84px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        padding-left: 8px;
                    }
                }
            }

            & .curren_fp_type {
                width: 100%;
                height: 38px;
                margin-bottom: 15px;

                & .fp_type {
                    display: flex;
                    width: 100%;
                    height: 38px;
                    display: flex;
                    align-items: center;

                    & .desc {
                        display: inline-block;
                        width: 130px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        text-align: right;
                    }


                }
            }
        }

        // 商品
        & .curren_priop {
            & /deep/.el-form-item {
                margin-bottom: 18px !important;
            }

            .curren_fp {
                margin-bottom: 12px;

                & .change_type {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 38px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    margin-bottom: 24px;

                    & .desc {
                        display: inline-block;
                        width: 130px;
                        text-align: right;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        margin-right: 8px;
                    }

                    & /deep/.el-radio__inner {
                        width: 16px;
                        height: 16px;
                        background: #FFFFFF;
                        border: 1px solid #E0E0E0;
                    }

                    & /deep/.el-radio__label {
                        display: inline-block;
                        // width: 84px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        padding-left: 8px;
                    }
                }


                & .upload {
                    width: 78px;
                    height: 78px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 1px dashed #EDEDED;
                    margin-left: 138px;
                    cursor: pointer;

                    & .skeletonize {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & div {
                            width: 100%;
                            height: 100%;

                            & i {
                                font-size: 24px;
                                color: #DAD9D9;
                            }

                            & img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                    }


                }

            }

            .curren_bo {
                .curren_fp_lx {
                    margin-bottom: 0px;
                    // margin-left: 60px;

                    & .inventory {
                        display: flex;
                        align-items: center;
                        flex-shrink: 0;
                        margin-bottom: 24px;
                    }

                    & .desc {
                        display: inline-block;
                        width: 130px;
                        text-align: right;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        margin-right: 8px;
                    }

                    & /deep/.el-checkbox {
                        margin-left: 0 !important;
                    }




                    .curren_tab {
                        & /deep/ tr th:nth-of-type(2) {
                            padding: 0 15px;
                        }

                        & /deep/.el-table .el-table__row .el-table__cell {
                            padding: 18px 0 0 !important;
                        }

                        & /deep/.touch {
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            background: url('~@/assets/images/touch.svg') center center no-repeat;
                            background-size: 100% 100%;
                            cursor: move;
                        }

                        & /deep/tr td .cell {
                            & .el-form-item__content {
                                display: flex;
                                align-items: center;
                            }


                            & .span {
                                padding: 0 15px;
                            }

                            & .el-icon-delete {
                                width: 16px;
                                height: 16px;
                                margin-left: 10px;
                                cursor: pointer;
                                display: none;

                                &:hover {
                                    color: var(--fontColor);
                                }

                            }
                        }

                        & /deep/.el-table__body tbody tr:hover .el-icon-delete {
                            display: block;
                        }

                        .bdnoe/deep/ .el-input__inner {
                            border: none;

                        }

                        .el-input {
                            position: relative;
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            // width: 290px;
                        }

                        position: relative;
                        width: 700px;
                        margin-left: 130px;
                        margin-top: 24px;

                        /deep/.el-table .el-table__header-wrapper .el-table__header thead tr th {
                            background-color: #f9f9fc !important;
                        }

                        .curren_tab_aicon {
                            position: absolute;
                            width: 38px;
                            height: 38px;
                            background: #f5f6fa;
                            border-radius: 0px 2px 2px 0px;
                            border: 1px solid #dddfe6;
                            right: 10px;
                            top: 190px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 38px;
                            text-align: center;
                        }

                        /deep/.el-table th.el-table__cell>.cell {
                            display: inline-block;
                            box-sizing: border-box;
                            font-size: 14px;
                            font-weight: 500;
                            color: #333333;
                            position: relative;
                            vertical-align: middle;
                            padding-left: 10px;
                            padding-right: 10px;
                            width: 100%;
                        }
                    }

                    /deep/ .el-button {
                        width: 100px;
                        margin-left: 130px;
                        margin-top: 16px;
                        height: 38px;
                        font-size: 12px;
                        color: #000d9a !important;
                        background: #FFFFFF;
                        border-radius: 3px;
                    }
                }
            }
        }

        // 发票
        .curren_xar {
            margin-bottom: -30px;

            .curren_fp {
                margin-bottom: 34px;

                // margin-left: 84px;
                & .desc {
                    display: inline-block;
                    width: 130px;
                    text-align: right;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    margin-right: 8px;
                }

                // span {
                //     font-size: 14px;
                //     color: #666;
                // }

                // .el-radio {
                //     margin-left: 13px;
                //     font-size: 14px;
                //     font-weight: 400;
                //     color: #333333;
                // }

                // .el-radio:last-child {
                //     margin-left: -4px;
                //     font-size: 14px;
                //     font-weight: 400;
                //     color: #333333;
                // }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 8px;
                    margin-left: 102px;
                }
            }

            // .active {
            //     margin-bottom: 34px;
            //     margin-left: 56px;

            //     span {
            //         font-size: 14px;
            //         color: #666;
            //     }

            //     .el-radio {
            //         margin-left: 13px;
            //         font-size: 14px;
            //         font-weight: 400;
            //         color: #333333;
            //     }

            //     .el-radio:last-child {
            //         margin-left: -4px;
            //         font-size: 14px;
            //         font-weight: 400;
            //         color: #333333;
            //     }

            //     p {
            //         font-size: 14px;
            //         font-weight: 400;
            //         color: #999999;
            //         margin-top: 8px;
            //         margin-left: 102px;
            //     }
            // }

            // .curren_fp:nth-of-type(6){
            //   font-size: 1px;
            // }
        }
    }
}
</style>